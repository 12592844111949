@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Works on Firefox */
* {
  font-family: 'Poppins', sans-serif;
}

/* Works on Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  border-radius: 20px;
  border: 2px solid rgb(96, 96, 96);
} */

.forge-spinner {
  display: none !important;
}